import ready from 'domready';
import animateScrollTo from 'animated-scroll-to';
const scrollMonitor = require('scrollmonitor');
import { tns } from "../../node_modules/tiny-slider/src/tiny-slider";
import GLightbox from 'glightbox';
import '../scss/index.scss';
import '../images/social.png';

class App {
    constructor() {
        this.loadedClass = 'loaded';
        this.header = document.querySelector('.site-header');
        this.headerInner = this.header.querySelector('.site-header__inner');
        this.headerHeight = this.headerInner.clientHeight;
        this.nav = document.querySelector('.site-nav');
        this.navBtn = this.nav.querySelector('.site-nav__btn');
        this.navLinks = document.querySelectorAll('.site-nav__list a, .content-nav a');
        this.navActiveClass = 'site-nav_active';
        this.shareToggle = document.querySelector('.share-toggle');
        this.shareToggleActiveClass = 'share-toggle_active';
        this.scrollWatcherOfsset = -500;
        this.mq = window.matchMedia('(min-width: 1024px)');
        this.glightbox = GLightbox();
        this.filesIDs = [];

        this.glightbox.on('slide_after_load', (data) => {
            const { slideIndex, slideNode, slideConfig, player, trigger } = data;
            const video = slideNode.querySelector('video');
            if (video && !video.hasAttribute('playsinline')) {
                video.setAttribute('playsinline', '');
            }
        });
    }
    offset(el) {
	    const rect = el.getBoundingClientRect(),
	    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
	    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }
    handlePageLoad() {
        document.querySelector('body').classList.add(this.loadedClass);
    }
    handleNavBtnClick() {
        this.navBtn.addEventListener('click', () => {
            this.nav.classList.contains(this.navActiveClass) ? this.nav.classList.remove(this.navActiveClass) : this.nav.classList.add(this.navActiveClass);
        });
    }
    handleNavLinkClick() {
        this.navLinks.forEach((link) => {
            link.addEventListener('click', (e) => {
                const target = e.currentTarget.getAttribute('href');
                this.nav.classList.remove(this.navActiveClass);
                
                e.preventDefault();
                animateScrollTo(document.querySelector(target), {
                    verticalOffset: -this.headerHeight
                });
            });
        });
    }
    handleMoreBtnClick() {
        const moreBtns = document.querySelectorAll('.btn_toggle');
        const buttonActiveClass = 'btn_toggle_active';
        moreBtns.forEach(button => {
            const initialText = button.innerText;

            button.addEventListener('click', e => {
                e.preventDefault();
                
                const id = e.currentTarget.getAttribute('href');
                const $item = document.querySelector(id);
                if (button.classList.contains(buttonActiveClass)) {
                    button.classList.remove(buttonActiveClass);
                    $item.classList.remove('d-block');
                    $item.classList.add('d-none');

                    button.innerText = initialText;

                    const itemOffset = this.offset($item);
                    document.documentElement.scrollTop = itemOffset.top - this.headerHeight;
                } else {
                    button.classList.add(buttonActiveClass);
                    $item.classList.add('d-block');
                    $item.classList.remove('d-none');

                    button.innerText = 'Скрыть';
                }
            });
        });
    }
    handleShareToggleClick() {
        let shareToggleBtn = this.shareToggle.querySelector('.share-toggle__btn');
        shareToggleBtn.addEventListener('click', (e) => {
            this.shareToggle.classList.toggle(this.shareToggleActiveClass);
        });
    }
    handleAddPhotos() {
        const $form = document.querySelector('.form_request');

        if ($form) {
            const $photo = $form.querySelector('#photo');
            const $otherPhotos = $form.querySelector('#other-photos');
            const $thumbs = $form.querySelector('.thumbs');

            if ($photo) {
                $photo.addEventListener('change', () => {
                    this.sendFile($photo.files[0], 'me')
                        .then(result => {
                            if (result.id) {
                                this.filesIDs.push(result.id);
                                $thumbs.insertAdjacentHTML('beforeend', `<div class="thumbs__item"><img src="${result.url}" /></div>`)
                            }
                        });
                    
                });
            }
            if ($otherPhotos) {
                $otherPhotos.addEventListener('change', () => {
                    $otherPhotos.files.forEach(file => {
                        this.sendFile(file, 'granny')
                            .then(result => {
                                if (result.id) {
                                    this.filesIDs.push(result.id);
                                    $thumbs.insertAdjacentHTML('beforeend', `<div class="thumbs__item"><img src="${result.url}" /></div>`)
                                }
                            });
                    });
                });
            }
        }
    }
    async sendFile(file, type) {
        const formData = new FormData();
        formData.set('file', file);

        try {
            const response = await fetch(`https://opolchenie.m24.ru/adm/v1/send-file?type=${type}`, {
                method: 'POST',
                body: formData
            });
            const result = await response.json();

            return result;
        } catch (error) {
            console.error('Ошибка:', error);
        }
    }
    handleSubmit() {
        const $form = document.querySelector('.form_request');
        if ($form) {
            const $submitBtn = $form.querySelector('button[type="submit"]');
            const $message = $form.querySelector('.message');
            $message.innerText = '';
            $message.classList.remove('d-block', 'message_success', 'message_error');
            $message.classList.add('d-none');
            $submitBtn.addEventListener('click', e => {
                e.preventDefault();
                $form.classList.add('validated');
                if ($form.checkValidity()) {
                    this.sendData();
                }
            });
        }
    }
    async sendData() {
        const $form = document.querySelector('.form_request');
        const $name = $form.querySelector('#name');
        const $phone = $form.querySelector('#phone');
        const $email = $form.querySelector('#email');
        const $text = $form.querySelector('#text');
        const $submitBtn = $form.querySelector('button[type="submit"]');
        const $message = $form.querySelector('.message');
        const formData = new FormData();

        $submitBtn.classList.add('loading');
        $submitBtn.setAttribute('disabled', 'disabled');

        formData.set('name', $name.value);
        formData.set('phone', $phone.value);
        formData.set('email', $email.value);
        formData.set('text', $text.value);

        this.filesIDs.map(id => {
            formData.append('files[]', id);
        });

        try {
            const response = await fetch('https://opolchenie.m24.ru/adm/v1/send-text', {
                method: 'POST',
                body: formData
            });
            const result = await response.json();

            if (result.success) {
                $message.innerText = 'Данные отправлены';
                $message.classList.remove('d-none');
                $message.classList.add('d-block', 'message_success');
            }
            if (result.validation_errors) {
                let errorsHTML = '';
                result.validation_errors.map(error => {
                    errorsHTML += `${error}<br>`;
                });
                $message.innerHTML = errorsHTML;
                $message.classList.remove('d-none');
                $message.classList.add('d-block', 'message_error');
            }
        } catch (error) {
            console.error('Ошибка:', error);
        }

        $submitBtn.classList.remove('loading');
        $submitBtn.removeAttribute('disabled');
    }
    initScrollWatch() {
        const headerWatcher = scrollMonitor.create(this.header, -this.headerHeight);
        const sections = document.querySelectorAll('.section');

        headerWatcher.enterViewport(() => {
            this.header.classList.remove('sticky');
        });
        headerWatcher.exitViewport(() => {
            this.header.classList.add('sticky');
        });

        sections.forEach(section => {
            let sectionWatcher = scrollMonitor.create(section, this.scrollWatcherOfsset);
            sectionWatcher.enterViewport(() => {
                section.classList.add('visible');
                let animatedElems = section.querySelectorAll('.animated');
                animatedElems.forEach(el => {
                    let animation = el.dataset.animation;
                    el.classList.add(animation);
                });
            });
        });
    }
    initCarousels() {
        const $militiaCarousel = document.querySelector('.carousel_militia');
        const $familyCarousel = document.querySelector('.carousel_family');
        const $filmSlider = document.querySelector('.slider_film');
        const $storiesSlider = document.querySelector('.slider_stories');

        if ($militiaCarousel) {
            let militiaCarousel = tns({
                container: $militiaCarousel,
                items: 1,
                controlsText: ["Предыдущий", "Следующий"],
                nav: false,
                autoplay: false,
                autoplayButtonOutput: false,
                swipeAngle: 30,
                responsive: {
                    768: {
                        items: 2,
                        gutter: 30
                    },
                    1024: {
                        items: 3,
                        gutter: 30
                    }
                },
            });
        }
        if ($familyCarousel) {
            let familyHTML = '';
            this.getFamilyData()
                .then(items => {
                    items.map(item => {
                        familyHTML += `<div class="carousel__item">
                                            <div class="video">
                                                <a href="${item.media.mp4}" class="glightbox">
                                                    <div class="video__thumb">
                                                        <img src="${item.image}">
                                                        <div class="video__play"></div>
                                                    </div>
                                                    <div class="video__title">${item.title}</div>
                                                </a>
                                            </div>
                                        </div>`;
                    });
                    $familyCarousel.innerHTML = familyHTML;
                    let familyCarousel = tns({
                        container: $familyCarousel,
                        items: 1,
                        nav: false,
                        autoplay: false,
                        autoplayButtonOutput: false,
                        swipeAngle: 30,
                        responsive: {
                            768: {
                                items: 2,
                                gutter: 30
                            },
                            1024: {
                                items: 3,
                                gutter: 30
                            }
                        },
                        onInit: () => {
                            if (this.glightbox) {
                                this.glightbox.reload();
                            }
                        }
                    });
                });
        }
        if ($filmSlider) {
            let filmSlider = tns({
                container: $filmSlider,
                items: 1,
                nav: false,
                autoplay: false,
                autoplayButtonOutput: false,
                swipeAngle: 30
            });
        }
        if ($storiesSlider) {
            let storiesSlider = tns({
                container: $storiesSlider,
                items: 1,
                nav: false,
                autoplay: false,
                autoplayButtonOutput: false,
                swipeAngle: 30,
                autoHeight: true
            });
        }
    }
    async getMilitiaData() {
        const $container = document.querySelector('#militia-content');
        try {
            const url = isDev ? 'militia.json' : 'https://www.m24.ru/api/v2?type=back&id=401&format=json';
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('данные бэка не получены');
            }
            const data = await response.json();
            $container.innerHTML = data[0].html;
            this.fixHtmlContent($container);
        } catch (error) {
            console.log('Возникла проблема с получением данных: ', error.message);
        }
    }
    async getFamilyData() {
        try {
            const url = isDev ? 'family.json' : 'https://www.m24.ru/api/v2?type=video&tag=%D0%93%D0%BE%D1%80%D0%BE%D0%B4%20%D0%B3%D0%B5%D1%80%D0%BE%D0%B5%D0%B2&columns=image';
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('данные программы не получены');
            }
            const items = await response.json();
            return items;
        } catch (error) {
            console.log('Возникла проблема с получением данных: ', error.message);
        }
    }
    fixHtmlContent(el) {
        let elHTML = el.innerHTML;
        const searchRegExp = /"\/b/g;
        const prefix = 'https://m24.ru';
        elHTML = elHTML.replace(searchRegExp, `"${prefix}/b`);
        el.innerHTML = elHTML;

        const lightbox = GLightbox({
            selector: '[data-fslightbox]'
        });
    }
    initShare() {
        const shareBtns = document.querySelectorAll('.share');
        shareBtns.forEach((buttons) => {
            let share = Ya.share2(buttons, {
                theme: {
                    services: 'vkontakte,odnoklassniki,facebook,twitter'
                }
            });
        });
    }
    async initNews() {
        const $news = document.querySelector('.news');
        let newsHTML = '';
        const url = isDev ? 'news.json' : 'https://www.m24.ru/api/v2?type=news&limit=3&columns=image&tag=Московское ополчение';
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('данные новостей не получены');
            }
            const items = await response.json();
            items.map(item => {
                newsHTML += `<div class="news__item"><a href="${item.link}" target="_blank"><img src="${item.image}" alt="${item.title}" /><h3>${item.title}</h3></a></div>`;
            });
            $news.innerHTML = newsHTML;
        } catch (error) {
            console.log('Возникла проблема с получением данных: ', error.message);
        }
    }
    init() {
        try {
            this.handlePageLoad();
            this.handleNavBtnClick();
            this.handleNavLinkClick();
            this.handleMoreBtnClick();
            this.handleShareToggleClick();
            this.handleAddPhotos();
            this.handleSubmit();
            this.initScrollWatch();
            this.initCarousels();
            this.initShare();
            this.initNews();
            this.getMilitiaData();
        } catch(error) {
            console.log(error);
        }
    } 
}

ready(() => {
    window.app = new App();
    window.app.init();
});